import React from 'react';
import { AnchorLabel } from 'styled/components/base';
import { NavLink } from '../MobileNavBar.style';
import { ArticleItem, ArticleItems } from './MobileArticles.style';

import type { TrendingArticle } from '../../types';
import { ArticleItemImage } from '../../Navigation.style';
import {
  RandomDefaultImage,
  getNavBarLinkLabel,
  getOMDLinkConfig,
  getTrendingArticleLink,
  shouldShowOMDLinks,
} from '../../utils';
import {
  trackUserClicksOMDLink,
  trackUserClicksTrendingArticle,
} from '../../analytics';
import { DIAGNOSTIC_TOOLS_LABEL } from './constants';
import useLanguage from 'hooks/useLanguage';
import { useGlobalSettings } from 'state/selectors/globalSettings';

type Props = {
  articles: TrendingArticle[];
  label: string;
  labelFormat?: string;
  onViewAllClick?: () => void;
  slug: string;
};

function MobileArticles(props: Props) {
  const language = useLanguage();
  const { trendingArticleImages } = useGlobalSettings();
  const { articles = [], label, labelFormat, onViewAllClick, slug } = props;

  const navBarLinkLabel = label.includes(DIAGNOSTIC_TOOLS_LABEL)
    ? ''
    : getNavBarLinkLabel(label, { labelFormat });

  return (
    <ArticleItems>
      {articles.map((article, i) => {
        const url = getTrendingArticleLink({
          article,
        });
        const trendingArticleImage = trendingArticleImages?.find(
          (imageConfig) => imageConfig?.articleId === article.id,
        );
        return (
          <ArticleItem key={article.slug}>
            <NavLink
              ariaLabel={article.title}
              to={url}
              onClick={() => trackUserClicksTrendingArticle(url)}
            >
              <ArticleItemImage
                color={trendingArticleImage?.heroColor}
                title={trendingArticleImage?.heroImage?.alt}
              >
                {trendingArticleImage?.heroImage?.src ? (
                  <img
                    src={`${trendingArticleImage?.heroImage.src}?h=200&auto=format`}
                  />
                ) : (
                  <RandomDefaultImage index={i} />
                )}
              </ArticleItemImage>
              <AnchorLabel>{article.title}</AnchorLabel>
            </NavLink>
          </ArticleItem>
        );
      })}

      {slug && navBarLinkLabel && (
        <ArticleItem>
          <NavLink
            ariaLabel={navBarLinkLabel}
            label={navBarLinkLabel}
            onClick={onViewAllClick}
            to={slug}
          />
        </ArticleItem>
      )}

      {shouldShowOMDLinks(slug) &&
        getOMDLinkConfig(language).map(({ url, label }) => (
          <ArticleItem key={url}>
            <NavLink
              ariaLabel={label}
              to={url}
              onClick={() => trackUserClicksOMDLink(url)}
              openInCurrentTab={false}
            >
              <AnchorLabel>{label}</AnchorLabel>
            </NavLink>
          </ArticleItem>
        ))}
    </ArticleItems>
  );
}

export default MobileArticles;
