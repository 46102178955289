import React, { useMemo } from 'react';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import useScrollDirection, { ScrollDirection } from 'hooks/useScrollDirection';
import BuoyLogo from 'styled/components/svg/BuoyLogo';
import Link from 'styled/components/Link';
import DesktopNavBar from '../DesktopNavBar';
import MobileNavBar from '../MobileNavBar';
import { NavBar, NavBarLeft, NavBarRight } from './Navigation.style';
import useBreakpointIsDesktop from 'hooks/useBreakpointIsDesktop';

import type { NavigationProps } from '../types';

function Navigation(props: NavigationProps) {
  const scrollDirection = useScrollDirection({
    initialDirection: ScrollDirection.SCROLL_UP,
  });

  const isDesktop = useBreakpointIsDesktop();

  const isVisible = useMemo(() => {
    if (scrollDirection !== ScrollDirection.SCROLL_DOWN) {
      return true;
    }

    return false;
  }, [scrollDirection]);

  if (!isVisible) {
    return null;
  }

  return (
    <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={0}>
      <Toolbar>
        <NavBar>
          <NavBarLeft>
            <Link ariaLabel="Buoy Health" to="/">
              <BuoyLogo />
            </Link>
          </NavBarLeft>

          <NavBarRight>
            {isDesktop ? (
              <DesktopNavBar {...props} />
            ) : (
              <MobileNavBar {...props} />
            )}
          </NavBarRight>
        </NavBar>
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
