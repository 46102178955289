import Sanity, { SanityClient as ISanityClient } from '@sanity/client';
const dataset = process.env.SANITY_DATASET || process.env.sanityDataset;
const PROJECT_ID = process.env.sanityProjectId
  ? process.env.sanityProjectId
  : '0';

/*
 * Sanity Client using updated API version
 * Separate client to avoid breaking other queries
 * while allowing to use newer version as needed
 */
const SanityClientV2024 = Sanity({
  projectId: PROJECT_ID,
  dataset,
  useCdn: true,
  apiVersion: 'v2024-09-24',
});
/*
 * Untokenized Frontend Sanity Client
 * This client can only read published content
 */
const SanityClient = Sanity({
  projectId: PROJECT_ID,
  dataset,
  useCdn: true,
});

/*
 * Tokenized Frontend Sanity Client
 * With the token, this client has read permissions for
 * draft/unpublished content, in addition to published content
 */
const SanityPreviewClient = Sanity({
  projectId: PROJECT_ID,
  dataset,
  token: process.env.sanityPreviewToken as string,
  useCdn: false,
});

class Client {
  private SanityClient: ISanityClient;

  constructor(client: ISanityClient) {
    this.SanityClient = client;
  }

  async getDocuments(ids: string[]) {
    return new Promise((resolve, reject) => {
      return this.SanityClient.getDocuments(ids)
        .then((response: unknown) => {
          return resolve(response);
        })
        .catch(reject);
    });
  }

  async getDocument(id: string) {
    return new Promise((resolve, reject) => {
      return this.SanityClient.getDocument(id)
        .then((response: unknown) => {
          return resolve(response);
        })
        .catch(reject);
    });
  }

  async fetch(query: string) {
    return new Promise((resolve, reject) => {
      return this.SanityClient.fetch(query)
        .then((response: unknown) => {
          return resolve(response);
        })
        .catch(reject);
    });
  }
}

export const PreviewClient = new Client(SanityPreviewClient);
export const v2024Client = new Client(SanityClientV2024);
export default new Client(SanityClient);
