import get from 'lodash/get';
import { GlobalSettingsId } from 'constants/SingletonDocumentId';
import BuoyClient from 'lib/BuoyClient';
import {
  ArticleLinkGroq,
  ArticleBodyModuleGroq,
  BlogCategoriesWithUnfrozenBlogsGroq,
  BlogFrozenGroq,
  DrugLinkGroq,
  ImageGroq,
  ModuleGroq,
} from 'lib/Groq';
import Sanity, { PreviewClient, v2024Client } from 'lib/SanityClient';
import sanitizeAllPages from 'state/sanitizers/sanitizeAllPages';
import sanitizeArticle from 'state/sanitizers/sanitizeArticle';
import sanitizeArticleLink from 'state/sanitizers/sanitizeArticleLink';
import sanitizeArticleGenericPage from 'state/sanitizers/sanitizeArticleGenericPage';
import sanitizeBlogPost from 'state/sanitizers/sanitizeBlogPost';
import sanitizeBlogPageCategory from 'state/sanitizers/sanitizeBlogPageCategory';
import sanitizeCampaignPage from 'state/sanitizers/sanitizeCampaignPage';
import sanitizeDrugPage from 'state/sanitizers/sanitizeDrugPage';
import sanitizeGenericPage from 'state/sanitizers/sanitizeGenericPage';
import sanitizeGlobalSettings from 'state/sanitizers/sanitizeGlobalSettings';
import sanitizeAuthorPage from 'state/sanitizers/sanitizeAuthorPage';
import sanitizeShowcasePage from 'state/sanitizers/sanitizeShowcasePage';
import sanitizeBrowsePage from 'state/sanitizers/sanitizeBrowsePage';
import sanitizeBrowsePageLinks from 'state/sanitizers/sanitizeBrowsePageLinks';
import sanitizeSlug from 'utils/sanitizeSlug';
import sanitizeQuiz from 'state/sanitizers/sanitizeQuiz';
import sanitizeTrendingArticlesImages from 'state/sanitizers/sanitizeTrendingArticlesImages';
import {
  ARTICLE_PAGE_SIZE,
  BLOG_CATEGORY_PAGE_SIZE,
  QUIZ_PAGE_SIZE,
} from 'lib/constants';
import validateSchema from 'utils/validateSchema';

import { QuizResponseSchema } from 'lib/SanityClient/schemas/quiz';
import { CountSchema } from 'lib/SanityClient/schemas/shared';
import { getLanguages, getPaginationIndicies } from 'lib/SanityClient/utils';
import type { PaginationOptions } from 'lib/SanityClient/types';
import type { Count } from 'lib/SanityClient/schemas/shared';
import type { Languages } from 'lib/SanityClient/schemas/languages';

import { GlobalSettingsReducer } from 'state/reducers/globalSettings';
import {
  Article,
  ArticleGenericPage,
  ArticleLink,
  ArticleType,
  AuthorPage,
  BlogPost,
  CampaignPage,
  DrugPage,
  Quiz,
  GenericPage,
  IBlogCategoryPage,
  IBrowsePage,
  IBrowsePageLink,
  QuizType,
  SanityDocument,
  ShowcasePage,
  TrendingArticleImage,
} from 'types';

import { sitemapDocs } from 'sitemap/queries';

// This client is used for Sanity content fetch actions

const ApiClient: {
  fetchArticle(options: {
    slug: string;
    preview?: string;
    language?: string;
    articleTypes?: ArticleType[];
  }): Promise<Article | null>;
  fetchBlogCategory(options: {
    filterByFrozen?: boolean;
    language?: string;
    limit?: number;
    slug: string;
    page: number;
  }): Promise<{ categoryPage: IBlogCategoryPage | null; count: number }>;
  fetchBlogPost(options?: {
    category?: string;
    filterByFrozen?: boolean;
    slug: string;
    preview?: string;
    page?: number | null;
  }): Promise<BlogPost | IBlogCategoryPage | null>;
  fetchBlogPosts(options?: {
    count?: number | null;
    filterByFrozen?: boolean;
    onlyFeatured?: boolean;
  }): Promise<BlogPost[] | null>;
  fetchBlogCategories(options?: {
    filterByFrozen?: boolean;
  }): Promise<IBlogCategoryPage[] | null>;
  fetchDrugPage(slug: string, preview?: string): Promise<DrugPage | null>;
  fetchCampaignPage(
    slug: string,
    preview?: string,
  ): Promise<CampaignPage | null>;
  fetchGenericPage(
    slug: string,
    preview?: string,
  ): Promise<GenericPage | ArticleGenericPage | null>;
  fetchGlobalSettings(): Promise<
    Omit<GlobalSettingsReducer, 'trendingArticleImages'>
  >;
  fetchTrendingArticlesImages(): Promise<TrendingArticleImage[]>;
  fetchAuthorPage(slug: string, preview?: string): Promise<AuthorPage | null>;
  fetchShowcasePage(
    slug: string,
    preview?: string,
  ): Promise<ShowcasePage | null>;
  fetchAllPages(): Promise<SanityDocument[]>;
  fetchBrowsePage(
    options: {
      slug: string;
      articleType: ArticleType;
      preview?: string;
    } & PaginationOptions,
  ): Promise<{ browsePage: IBrowsePage | null; count: number }>;
  fetchBrowsePageLinks(
    taxonomy: string[],
    type: ArticleType | null,
    preview?: string,
  ): Promise<IBrowsePageLink[] | []>;
  fetchDxArticles(
    options: PaginationOptions,
  ): Promise<{ articles: ArticleLink[] | null; count: number }>;
  fetchArticleLanguages(options: {
    articleType: ArticleType;
  }): Promise<Languages>;
  fetchArticleLanguagesByTaxonomy(options: {
    articleType: ArticleType;
    slug: string;
  }): Promise<Languages>;
  fetchBlogLanguages(options: {
    category: string;
    frozen: boolean;
  }): Promise<Languages>;
  fetchSxArticles(
    options: PaginationOptions,
  ): Promise<{ articles: ArticleLink[] | null; count: number }>;
  fetchOtherArticles(
    options: PaginationOptions,
  ): Promise<{ articles: ArticleLink[] | null; count: number }>;
  fetchCostArticles(
    options: PaginationOptions,
  ): Promise<{ articles: ArticleLink[] | null; count: number }>;
  fetchQuiz(slug: string): Promise<Quiz | null>;
  fetchQuizzes(
    options: {
      type: QuizType;
      titleStartsWith?: string;
    } & PaginationOptions,
  ): Promise<{ quizzes: Quiz[] | null; count: number }>;
} = {
  async fetchArticle({ slug, preview = '', language, articleTypes }) {
    const groq = `{
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'heroImage': heroImage${ImageGroq},
      'heroDesktopImage': heroDesktopImage${ImageGroq},
      'heroMobileImage': heroMobileImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      'author': author->{ image${ImageGroq}, ... },
      'reviewer': reviewer->{ image${ImageGroq}, ... },
      'moreArticles': moreArticles[]->${ArticleLinkGroq},
      'articleBodyModuleRepeater': articleBodyModuleRepeater[] ${ArticleBodyModuleGroq},
      'moduleImage': moduleImage${ImageGroq},
      'pauseUxCtaImage': pauseUxCtaImage${ImageGroq},
      'pauseUxCtaItems': pauseUxCtaItems[] { image${ImageGroq}, ... },
      'treatmentsPage': *[_type == 'treatmentsPage' && references(^._id)][0],
      'translatedPages': translatedPages[]->{ language, slug },
      ...
    }`;
    const isInternational = language && language !== 'en';
    const languageGroq = language
      ? `&& language == '${isInternational ? `${language}` : 'en'}'`
      : '';
    const articleTypeGroq = articleTypes
      ? `&& articleType in ["${articleTypes.join('","')}"]`
      : '';

    const articleQuery = `*[_type == 'article' && slug == ${sanitizeSlug(
      slug,
    )} ${languageGroq} ${articleTypeGroq}]| order(_updatedAt desc)[0] ${groq}`;

    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'article' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(articleQuery);
    const type = get(response, '_type');
    const id = get(response, '_id');

    if (type === 'article') {
      const userStories = await BuoyClient.getArticleUserStories(id, 3, 0);
      const sanitizedArticle = sanitizeArticle(response, userStories);
      const articleRating = await BuoyClient.getArticleOrDrugPageRatings(id);

      if (!sanitizedArticle) return null;

      const browsePageLinks = await this.fetchBrowsePageLinks(
        sanitizedArticle.taxonomy,
        sanitizedArticle.articleType,
        preview,
      );

      return {
        ...sanitizedArticle,
        browsePageLinks,
        content: {
          ...sanitizedArticle.content,
          articleRating,
        },
      };
    }

    return null;
  },
  async fetchBlogCategory(options) {
    const {
      filterByFrozen = false,
      slug,
      page = 1,
      language = 'en',
      limit = BLOG_CATEGORY_PAGE_SIZE,
    } = options || {};
    const groq = `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      ...
    }`;
    const blogPostGroq = `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      'author': author->{ ... },
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'categories': categories[]->{ ... },
      'language': select(
        region == 'us' => "en",
        defined(region) => lower(region),
        "en"
      ),
      ...
    }`;
    const response: any = await Sanity.fetch(
      `*[_type == 'blogCategory' && slug == '${slug}'] ${groq}`,
    );
    if (response.length === 0) {
      return {
        categoryPage: null,
        count: 0,
      };
    }
    const id = get(response[0], '_id');
    // Match language to whats in Sanity
    // Most old blogs dont have a region - assume english
    const langGroq =
      language === 'en'
        ? `&& (region == "us" || !defined(region))`
        : `
      && (defined(region) && region == "${language.toUpperCase()}")
`;
    // Fetch paginated blog posts associated with this category
    const { start, end } = getPaginationIndicies({ pageNumber: page, limit });
    const blogPostsInCategory = (await Sanity.fetch(
      `*[_type == 'blogPost' && "${id}" in categories[]._ref ${BlogFrozenGroq(filterByFrozen)} ${langGroq} ] | order(lower(title))[${start}..${end}] ${blogPostGroq}`,
    )) as unknown[];

    // Fetch total count of blog posts (will be used for pagination)
    const blogPostsInCategoryCount = (await Sanity.fetch(
      `count(*[_type == 'blogPost' && "${id}" in categories[]._ref ${BlogFrozenGroq(filterByFrozen)} ${langGroq}])`,
    )) as number;
    return {
      categoryPage: sanitizeBlogPageCategory(
        response[0],
        blogPostsInCategoryCount,
        blogPostsInCategory,
      ),
      count: blogPostsInCategoryCount,
    };
  },
  async fetchBlogPost(options) {
    const {
      category = '',
      filterByFrozen = false,
      preview = '',
      slug = '',
    } = options || {};

    const groq = `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      'author': author->{ ... },
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'categories': categories[]->{ ... },
      ...
    }`;

    const categoryGroq = category
      ? `&& count((categories[] -> slug)[@ in["${category}"]]) > 0 `
      : '';

    const response: any = preview
      ? await PreviewClient.fetch(
          `*[(_type in ['blogPost']) && _id == '${preview}' ${categoryGroq} ${BlogFrozenGroq(filterByFrozen)}]| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[(_type in ['blogPost']) && slug == ${sanitizeSlug(
            slug,
          )} ${categoryGroq} ${BlogFrozenGroq(filterByFrozen)}]| order(_updatedAt desc)[0] ${groq}`,
        );

    return sanitizeBlogPost(response);
  },
  async fetchBlogPosts(options) {
    const {
      count = null,
      filterByFrozen = false,
      onlyFeatured = false,
    } = options || {};

    const groq = `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      'author': author->{ ... },
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'categories': categories[]->{ ... },
      ...
    }`;
    const response: any = await Sanity.fetch(
      `*[_type == 'blogPost'${
        onlyFeatured ? '&& featured == true' : ''
      } ${BlogFrozenGroq(filterByFrozen)}]| order(_createdAt desc)${count ? `[0..${count - 1}]` : ''} ${groq}`,
    );
    return (
      response?.map((bp: any) => sanitizeBlogPost(bp)).filter(Boolean) || null
    );
  },
  async fetchBlogCategories(options) {
    const { filterByFrozen = true } = options || {};
    const groq = filterByFrozen
      ? `{
      'heroImage': heroImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      ...
    }`
      : BlogCategoriesWithUnfrozenBlogsGroq;
    const response: any = await Sanity.fetch(
      `*[_type == 'blogCategory']| order(title asc) ${groq}`,
    );
    return (
      response?.map((r: any) => sanitizeBlogPageCategory(r)).filter(Boolean) ||
      null
    );
  },
  async fetchDrugPage(slug, preview = '') {
    const groq = `{
      'metaImage': metaImage${ImageGroq},
      'author': author->{ ..., image${ImageGroq} },
      'relatedDrugs': relatedDrugs[]->{ ... },
      'relatedDiagnoses': relatedDiagnoses[]->${ArticleLinkGroq},
      ...
    }`;

    const sanityResponse = preview
      ? await PreviewClient.fetch(
          `*[(_type == 'drug') && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[(_type == 'drug') && slug == ${sanitizeSlug(
            slug,
          )}]| order(_updatedAt desc)[0] ${groq}`,
        );

    const id = preview || get(sanityResponse, '_id');
    const reviewResponse = await BuoyClient.getDrugReviews(id, 2, 0);
    const sideEffectResponse = await BuoyClient.getPopularDrugSideEffects(id);
    const pageRatings = await BuoyClient.getArticleOrDrugPageRatings(id);

    return sanitizeDrugPage(
      sanityResponse,
      reviewResponse,
      sideEffectResponse,
      pageRatings,
    );
  },
  async fetchCampaignPage(slug, preview = '') {
    const groq = `{
      ...,
      'metaImage': metaImage${ImageGroq},
      'image': image${ImageGroq},
    }`;
    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'campaignPage' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[_type == 'campaignPage' && slug == ${sanitizeSlug(
            slug,
          )}]| order(_updatedAt desc)[0] ${groq}`,
        );

    return sanitizeCampaignPage(response);
  },
  async fetchGenericPage(slug, preview = '') {
    const groq = `{
      ...,
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'heroModuleRepeater': heroModuleRepeater[] ${ModuleGroq},
      'articleBodyModuleRepeater': articleBodyModuleRepeater[] ${ArticleBodyModuleGroq},
      'heroDesktopImage': heroDesktopImage${ImageGroq},
      'heroMobileImage': heroMobileImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
    }`;
    const response = preview
      ? await PreviewClient.fetch(
          `*[(_type == 'genericPage' && _id == '${preview}') || (_type == 'articleGenericPage' && _id == '${preview}')]| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[(_type == 'genericPage' && slug == ${sanitizeSlug(
            slug,
          )}) || (_type == 'articleGenericPage' && slug == ${sanitizeSlug(
            slug,
          )})]| order(_updatedAt desc)[0] ${groq}`,
        );

    if (get(response, '_type') === 'genericPage') {
      return sanitizeGenericPage(response);
    } else if (get(response, '_type') === 'articleGenericPage') {
      return sanitizeArticleGenericPage(response);
    }
    return null;
  },
  async fetchGlobalSettings() {
    const response =
      await Sanity.fetch(`*[_type == 'globalSettings' && _id == '${GlobalSettingsId}']| order(_updatedAt desc)[0] {
      cookiesSlug,
      displayNotificationBar,
      displayPopUp,
      errorPageDescription,
      'errorPageImage': errorPageImage${ImageGroq},
      errorPageLinkLabel,
      errorPageLinkUrl,
      errorPageTitle,
      'footer': {
        'footerImage': {
          'image': footerImage${ImageGroq},
          'alternateImage': footerAlternateImage${ImageGroq},
          'text': footerImageText
        }
      },
      footerMenu,
      footerSocial,
      instagramToken,
      metaDescription,
      'metaImage': metaImage${ImageGroq},
      metaTitle,
      notificationBarText,
      privacySlug,
      'sideNavigation': sideNavigation[] {
        ...,
        'trendingArticles': trendingArticles[] -> {
          'id': _id,
          frozen,
          slug,
          title,
          'type': _type,
          articleType,
          language,
          'category': categories[0]->{ slug },
        },
      },
      termsSlug,
    }`);

    return sanitizeGlobalSettings(response);
  },
  async fetchTrendingArticlesImages() {
    const response: any = await v2024Client.fetch(
      `*[
        _type == 'globalSettings' &&
        _id == 'd286fb64-e3d2-427e-b335-68bafc3b498c'
      ] | order(_updatedAt desc)[0] {
        'trendingArticleImages': sideNavigation[defined(trendingArticles)].trendingArticles[] -> {
            'articleId': _id,
            'heroImage': heroImage${ImageGroq},
            heroColor,
          }
        }
      `,
    );

    return sanitizeTrendingArticlesImages(
      response?.trendingArticleImages || [],
    );
  },
  async fetchAuthorPage(slug, preview = '') {
    const groq = `{
      ...,
      'image': image${ImageGroq},
      'articles': *[_type=='article' && language=='en' && references(^._id)] | order(_updatedAt desc) ${ArticleLinkGroq}[0..9],
      'educationAndTraining': educationAndTraining[] {..., 'logo': logo${ImageGroq}},
      'practiceNames': practiceNames[] {..., 'logo': logo${ImageGroq}}
    }`;
    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'author' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[_type == 'author' && slug == ${sanitizeSlug(
            slug,
          )}]| order(_updatedAt desc)[0] ${groq}`,
        );

    return sanitizeAuthorPage(response);
  },
  async fetchShowcasePage(slug, preview = '') {
    const groq = `{
      ...,
      'moduleRepeater': moduleRepeater[] ${ModuleGroq},
      'heroModuleRepeater': heroModuleRepeater[] ${ModuleGroq},
      'heroDesktopImage': heroDesktopImage${ImageGroq},
      'heroMobileImage': heroMobileImage${ImageGroq},
    }`;
    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'showcasePage' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(
          `*[_type == 'showcasePage' && slug == ${sanitizeSlug(
            slug,
          )}]| order(_updatedAt desc)[0] ${groq}`,
        );

    return sanitizeShowcasePage(response);
  },
  async fetchAllPages() {
    const response = (await Sanity.fetch(sitemapDocs)) as unknown[];

    return sanitizeAllPages(response);
  },
  async fetchBrowsePage(options) {
    const {
      slug,
      articleType,
      pageNumber = 1,
      language = 'en',
      limit = ARTICLE_PAGE_SIZE,
      preview,
    } = options;
    const groq = `{
      'heroImage': heroImage${ImageGroq},
      'heroDesktopImage': heroDesktopImage${ImageGroq},
      'heroMobileImage': heroMobileImage${ImageGroq},
      'metaImage': metaImage${ImageGroq},
      ...
    }`;
    const { start, end } = getPaginationIndicies({ pageNumber, limit });

    const query = `*[_type == 'browsePage' && type == '${articleType}' && slug == ${sanitizeSlug(
      slug,
    )}]| order(_updatedAt desc)[0] ${groq}`;

    const response = preview
      ? await PreviewClient.fetch(
          `*[_type == 'browsePage' && _id == '${preview}']| order(_updatedAt desc)[0] ${groq}`,
        )
      : await Sanity.fetch(query);

    const taxonomyKey = articleType === 'Sx' ? 'sxTaxonomy' : 'dxTaxonomy';
    const id = get(response, '_id');
    const taxonomy = get(response, taxonomyKey);

    if (id) {
      const pagesInTaxonomyBaseQuery = `_type in ['article', 'drug'] && articleType == '${articleType}' && (("${taxonomy}" in taxonomy) || ("${taxonomy}" in ${taxonomyKey})) && language == '${language}'`;

      const pagesInTaxonomy = (await Sanity.fetch(
        `*[${pagesInTaxonomyBaseQuery}]| order(lower(name)) | order(lower(title))[${start}..${end}] 
            {
              _type == 'article' => ${ArticleLinkGroq},
              _type == 'drug' => ${DrugLinkGroq}
            }
          `,
      )) as unknown[];
      const count = (await Sanity.fetch(
        `count(*[${pagesInTaxonomyBaseQuery}])
          `,
      )) as number;

      return {
        browsePage: sanitizeBrowsePage(response, [...pagesInTaxonomy]),
        count,
      };
    }

    return {
      browsePage: null,
      count: 0,
    };
  },
  async fetchBrowsePageLinks(taxonomy, articleType, preview) {
    const groq = `{
      _id,
      slug,
      title
    }`;

    const taxonomyKey = articleType === 'Sx' ? 'sxTaxonomy' : 'dxTaxonomy';
    const query = `_type == "browsePage" && type == "${articleType}" && ${taxonomyKey} in [${taxonomy.map(
      (t) => `"${t}"`,
    )}]`;

    const response = preview
      ? await PreviewClient.fetch(`*[${query} && _id == '${preview}'] ${groq}`)
      : await Sanity.fetch(`*[${query}] ${groq}`);

    if ((response as [])?.length) {
      return sanitizeBrowsePageLinks(response, articleType);
    }

    return [];
  },
  async fetchDxArticles(options) {
    const {
      pageNumber = 1,
      limit = ARTICLE_PAGE_SIZE,
      language = 'en',
    } = options;
    const { start, end } = getPaginationIndicies({ pageNumber, limit });
    const query = `
      *[_type == 'article' && articleType == '${ArticleType.Dx}' && language == '${language}'] | order(lower(title)) [${start}..${end}] ${ArticleLinkGroq}
    `;
    const response = (await Sanity.fetch(query)) as ArticleLink[];
    const countQuery = `count(*[_type == 'article' && articleType == '${ArticleType.Dx}' && language == '${language}'] )`;
    const count = await Sanity.fetch(countQuery);
    const validatedCount: Count = validateSchema({
      schema: CountSchema,
      data: count,
      defaultValue: 0,
    });

    return {
      articles: response.map(sanitizeArticleLink),
      count: validatedCount,
    };
  },
  async fetchArticleLanguages(options) {
    const query = `array::unique(*[_type == 'article' && articleType == '${options.articleType}' && defined(language)].language)`;
    return await getLanguages(query);
  },
  async fetchArticleLanguagesByTaxonomy(options) {
    // Fetch browse page and get its taxonomy
    const taxonomyKey =
      options.articleType === ArticleType.Dx ? 'dxTaxonomy' : 'sxTaxonomy';
    const browsePage = await Sanity.fetch(
      `*[_type == 'browsePage' && type == '${options.articleType}' && slug == ${sanitizeSlug(
        options.slug,
      )}][0]{'taxonomy': ${taxonomyKey}}`,
    );
    // Fetch article languages for given article type and taxonomy
    const taxonomy = get(browsePage, 'taxonomy');
    const languagesQuery = `array::unique(*[_type == 'article' && articleType == '${options.articleType}' && defined(language) && "${taxonomy}" in ${taxonomyKey}].language)`;
    return await getLanguages(languagesQuery);
  },
  async fetchBlogLanguages(options) {
    const query = `array::unique(*[_type == 'blogPost' && count((categories[]->slug)[@ == "${options.category}"]) > 0 ${BlogFrozenGroq(options.frozen)}]{
      'language': select(
                   region == 'us' => "en",
                   defined(region) => lower(region),
                   "en"
                  )
    }.language)`;
    return await getLanguages(query);
  },

  async fetchSxArticles(options) {
    const {
      pageNumber = 1,
      limit = ARTICLE_PAGE_SIZE,
      language = 'en',
    } = options;
    const { start, end } = getPaginationIndicies({ pageNumber, limit });
    const query = `
      *[_type == 'article' && articleType == '${ArticleType.Sx}' && language == '${language}'] | order(lower(title))[${start}..${end}] ${ArticleLinkGroq}
    `;
    const response = (await Sanity.fetch(query)) as ArticleLink[];
    const countQuery = `count(*[_type == 'article' && articleType == '${ArticleType.Sx}' && language == '${language}'])`;
    const count = await Sanity.fetch(countQuery);
    const validatedCount: Count = validateSchema({
      schema: CountSchema,
      data: count,
      defaultValue: 0,
    });

    return {
      articles: response.map(sanitizeArticleLink),
      count: validatedCount,
    };
  },
  async fetchOtherArticles(options) {
    const {
      pageNumber = 1,
      limit = ARTICLE_PAGE_SIZE,
      language = 'en',
    } = options;
    const { start, end } = getPaginationIndicies({ pageNumber, limit });
    const query = `
      *[_type == 'article' && articleType == '${ArticleType.Other}' && language == '${language}']| order(lower(title)) [${start}..${end}] ${ArticleLinkGroq}
    `;
    const response = (await Sanity.fetch(query)) as ArticleLink[];
    const countQuery = `count(*[_type == 'article' && articleType == '${ArticleType.Other}' && language == '${language}'])`;
    const count = (await Sanity.fetch(countQuery)) as number;
    const validatedCount: Count = validateSchema({
      schema: CountSchema,
      data: count,
      defaultValue: 0,
    });

    return {
      articles: response.map(sanitizeArticleLink),
      count: validatedCount,
    };
  },
  async fetchCostArticles(options) {
    const {
      pageNumber = 1,
      limit = ARTICLE_PAGE_SIZE,
      language = 'en',
    } = options;
    const { start, end } = getPaginationIndicies({ pageNumber, limit });
    const query = `
      *[_type == 'article' && articleType == '${ArticleType.Cost}' && language == '${language}']| order(lower(title)) [${start}..${end}] ${ArticleLinkGroq}
    `;
    const response = (await Sanity.fetch(query)) as ArticleLink[];
    const countQuery = `count(*[_type == 'article' && articleType == '${ArticleType.Cost}' && language == '${language}'])`;
    const count = await Sanity.fetch(countQuery);
    const validatedCount: Count = validateSchema({
      schema: CountSchema,
      data: count,
      defaultValue: 0,
    });
    return {
      articles: response.map(sanitizeArticleLink),
      count: validatedCount,
    };
  },
  async fetchQuiz(slug) {
    const query = `
    *[_type == "quiz" && quizType in ['${QuizType.Dx}', '${QuizType.Sx}'] && slug["current"] == '${slug}'][0] {
      'author': author->{ image${ImageGroq}, ... },
      'reviewer': reviewer->{ image${ImageGroq}, ... },
      ...
    }
    `;
    const response = await Sanity.fetch(query);
    const validatedResponse = validateSchema({
      schema: QuizResponseSchema,
      data: response,
    });

    const sanitizedQuiz = sanitizeQuiz(validatedResponse);
    return sanitizedQuiz;
  },

  async fetchQuizzes(options) {
    const { limit = QUIZ_PAGE_SIZE, pageNumber = 1 } = options;
    const { start, end } = getPaginationIndicies({ pageNumber, limit });
    const titleStartsWithGroq = options.titleStartsWith
      ? `&& predictorName match "${options.titleStartsWith}*" && title match "${options.titleStartsWith}*"`
      : '';
    const query = `
    *[_type == "quiz" && quizType == '${options.type}' ${titleStartsWithGroq} && !(_id in path("drafts.**"))]
    | order(lower(title)) [${start}..${end}] 
    {
      'author': author->{ image${ImageGroq}, ... },
      'reviewer': reviewer->{ image${ImageGroq}, ... },
      ...
    }
    `;
    const response = await Sanity.fetch(query);

    const validatedResponse = validateSchema({
      schema: QuizResponseSchema.array(),
      data: response,
    });

    const countQuery = `count(*[_type == "quiz" && quizType == '${options.type}' ${titleStartsWithGroq} && !(_id in path("drafts.**"))])`;
    const count = await Sanity.fetch(countQuery);
    const validatedCount: Count = validateSchema({
      schema: CountSchema,
      data: count,
      defaultValue: 0,
    });

    const result: Quiz[] = validatedResponse.map(sanitizeQuiz);

    return {
      quizzes: result,
      count: validatedCount,
    };
  },
};

export default ApiClient;
